import { transportType, expensesStatus, approvalStep, carType, newExpensesStatus } from '../../dictionary/dictionaryId'
// 用车记录
export const carRecordForm = [
    { tag: "Select", key: 1, name: "shopId", "label": "", code: "salary_model", placeholder: "上车门市", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "destinationFlag", "label": "", placeholder: "输送类型", options: transportType },
    { tag: "Select", key: 3, name: "carAttribute", "label": "", placeholder: "车辆属性", options: [] },
    { tag: "Select", key: 4, name: "status", "label": "", placeholder: "审批状态", options: expensesStatus },
    { tag: "RangePicker", key: 5, name: "time", "label": "发车时间", placeholder: "发车时间" },
    { tag: "RangePicker", key: 6, name: "receiveTime", "label": "接收时间", placeholder: "接收时间" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
]
// 通勤记录明细
export const carDetailForm = [
    { tag: "Select", key: 1, name: "customerId", "label": "", code: "salary_model", placeholder: "输送企业", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "supplierId", "label": "", placeholder: "供应商", options: [], showSearch: true, },
    { tag: "Select", key: 3, name: "category", "label": "", placeholder: "用车类别", options: carType },
    { tag: "Select", key: 4, name: "approveStatus", "label": "", placeholder: "审批状态", options: expensesStatus },
    { tag: "RangePicker", key: 5, name: "time", "label": "发车时间", placeholder: "发车时间" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
]

// 财务视角 人员明细查询
export const carFarePersonRecordForm = [
    { tag: "Select", key: 1, name: "shopId", "label": "", code: "salary_model", placeholder: "归属门店", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "destinationFlag", "label": "", placeholder: "输送类型", options: transportType },
    { tag: "Select", key: 3, name: "carAttribute", "label": "", placeholder: "车辆属性", options: [] },
    { tag: "Select", key: 6, name: "carType", "label": "", placeholder: "车辆类型", options: [] },
    { tag: "Select", key: 4, name: "status", "label": "", placeholder: "审批状态", options: expensesStatus },
    { tag: "RangePicker", key: 5, name: "time", "label": "发车时间", placeholder: "发车时间" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
]
// 车费审批
export const costApprovalForm = [
    { tag: "Select", key: 1, name: "ownerDept", "label": "", placeholder: "提交门市", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "status", "label": "", placeholder: "报销状态", options: expensesStatus },
    { tag: "Select", key: 3, name: "step", "label": "", placeholder: "当前审批步骤", options: approvalStep },
    { tag: "RangePicker", key: 4, name: "time", "label": "提交时间", placeholder: "提交时间" },
    { tag: "Search", key: 5, name: "search", "label": "查询" },
]
// 车费审批 -新版
export const newCostApprovalForm = [
    // { tag: "Select", key: 1, name: "ownerDept", "label": "", placeholder: "提交门市", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "status", "label": "", placeholder: "审批状态", options: newExpensesStatus },
    { tag: "RangePicker", key: 4, name: "time", "label": "提交时间", placeholder: "提交时间" },
    { tag: "Search", key: 5, name: "search", "label": "查询" },
]
// 用车记录详情form
export const carPersonnelDetailForm: any = [
    { tag: "Select", key: 1, name: "shopId", "label": "", placeholder: "上车门市", showSearch: true, options: [] },
    { tag: "Select", key: 2, name: "destinationFlag", "label": "", placeholder: "输送类型", options: transportType },
    // { tag: "Select", key: 3, name: "zpStatus", "label": "", placeholder: "招聘状态", showSearch: true, options: [] },
    { tag: "RangePicker", key: 5, name: "time", "label": "上车时间", placeholder: "上车时间" },
    { tag: "RangePicker", key: 6, name: "receiveTime", "label": "接收时间", placeholder: "接收时间" },
    { tag: "Search", key: 7, name: "search", "label": "查询" },
]

export const typeOptions = [
    { label: '门店线路', value: 1 },
    { label: '客服线路', value: 2 },
    { label: '宿服线路', value: 3 },
]

export const approvalStatusOptions = [
    { label: '审核中', value: 1 },
    { label: '审核通过', value: 2 },
    { label: '审核不通过', value: -1 },
]

// 车费配置
export const costConfigurationForm = [
    { tag: "Select", key: 1, name: "type", "label": "", placeholder: "线路类型", showSearch: true, options: typeOptions },
    { tag: "Select", key: 2, name: "approvalStatus", "label": "", placeholder: "审批状态", options: approvalStatusOptions },
    { tag: "RangePicker", key: 3, name: "time", "label": "创建时间", placeholder: "创建时间" },
    { tag: "Search", key: 4, name: "search", "label": "查询" },
]
// 当前步骤,步骤，0：发起，1：门店经理，2：区域经理，3：副总，4：财务
export const approvalProcessList = [
    { label: "审批发起", value: 0 },
    { label: "门店经理审批", value: 1 },
    { label: "区域经理审批", value: 2 },
    { label: "副总审批", value: 3 },
    { label: "财务放款", value: 4 },
]

export const carDetailProcessList = [
    { label: "审批发起", value: 0 },
    { label: "部门经理审批", value: 1 },
    { label: "财务放款", value: 2 },
]

export enum tabEnum {
    carRecord = 'carRecord',
    costApproval = 'costApproval',
    newCostApproval = 'newCostApproval',
    costStatistics = 'costStatistics',
    carDetail = 'carDetail',
    costConfiguration = 'costConfiguration',
}
