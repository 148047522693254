/**
 * @ Author: Your name
 * @ Create Time: 2024-07-17 14:36:02
 * @ Modified by: Your name
 * @ Modified time: 2024-10-07 17:09:08
 * @ Description: 基础数据
 */

export const defaultValueOption = [
  { label: '多选', value: 0 },
  { label: '单选', value: 1 },
]

export const controlList = [
  { formType: 'text', key: 1, type: 1, label: '文本', classNmae: 'control_ibrary_icon_text' },
  { formType: 'textarea', key: 2, type: 2, label: '多行文本', classNmae: 'control_ibrary_icon_multi_line_text' },
  { formType: 'number', key: 3, type: 5, label: '数字', classNmae: 'control_ibrary_icon_number' },
  { formType: 'floatnumber', key: 4, type: 6, label: '金额', classNmae: 'control_ibrary_icon_money' },
  { formType: 'date', key: 5, type: 4, label: '日期', classNmae: 'control_ibrary_icon_date' },
  { formType: 'datetime', key: 6, type: 13, label: '日期+时间', classNmae: 'control_ibrary_icon_datetime' },
  { formType: 'select', key: 7, type: 3, label: '单选框', classNmae: 'control_ibrary_icon_radio' },
  { formType: 'checkbox', key: 8, type: 9, label: '多选框', classNmae: 'control_ibrary_icon_checkbox' },
  { formType: 'user', key: 9, type: 10, label: '成员', classNmae: 'control_ibrary_icon_member' },
  { formType: 'structure', key: 10, type: 12, label: '部门', classNmae: 'control_ibrary_icon_department' },
  { formType: 'file', key: 11, type: 8, label: '附件', classNmae: 'control_ibrary_icon_enclosure' },
  { formType: 'detail_table', key: 12, type: 45, label: '明细', classNmae: 'control_ibrary_icon_detailed' },
  { formType: 'desc_text', key: 13, type: 50, label: '说明文字', classNmae: 'control_ibrary_icon_desc' },
  { formType: 'website', key: 14, type: 25, label: '链接', classNmae: 'control_ibrary_icon_link' },
]
export const ctrolNmaeOptions = [
  {
    label: '申请人',
    value: 1
  },
  {
    label: '数字',
    value: 2
  },
  {
    label: '金额',
    value: 3
  },
  {
    label: '单选框',
    value: 4
  },
  {
    label: '多选框',
    value: 5
  },
]
export const handleGetLabel = (type: number) => {
  let label: string = '';
  const arr = controlList.filter(el => el.type === type);
  if (arr.length) {
    label = arr[0].label;
  }
  return label;
}

export const handleDealOption = (options: string) => {
  if (options.length) {
    const arr = options.split(',');
    return arr;
  }
}

export const examineModel: any = {
  examineType: 1,
  name: '审批人',
  conditionList: [],
  deptList: [],
  examineErrorHandling: 1, // 1 自动通过 2 管理员审批
  parentLevel: 1, // 发起人的上级
  tempParentLevel: 1, // 临时变量
  roleId: '',
  roleTypeId: '',
  type: 3,
  userList: [],
  chooseType: 1, // 1 自选一人 2 自选多人
  overType: 1, // 同时不超过发起人向上的 1 0 临时变量
  rangeType: 1, // 1 全公司 2 指定成员 3 指定角色
  isError: false,
  id: 1
}
export const conditionListModel: any = {
  examineType: 0, // 0 条件 1 指定成员 2 主管 3 角色 4 发起人自选 5 连续多级主管
  name: '',
  id: 1,
  conditionList: [
    {
      conditionName: '条件',
      conditionDataList: [],
      examineDataList: [],
      isError: false,
    },
    {
      conditionName: '默认条件',
      conditionDataList: [],
      examineDataList: [],
      isError: false
    }
  ]
}
export const examineTypeObj: { [key: number]: any } = {
  1: '指定成员',
  2: '上级',
  3: '角色',
  4: '发起人自选',
  5: '连续多级上级'
}

export const wayTypeObj: { [key: number]: any } = {
  1: '依次审批',
  2: '会签',
  3: '或签'
}


export const selectOptions = [{
  label: '属于',
  value: 7
}]

export const checkboxOptions = [{
  label: '完全包括',
  value: 11
}, {
  label: '其一包括',
  value: 7
}]

export const numberOptions = [{
  label: '小于',
  value: 3
}, {
  label: '大于等于',
  value: 4
}, {
  label: '等于',
  value: 1
}, {
  label: '大于',
  value: 2
}, {
  label: '小于等于',
  value: 5
}, {
  label: '介于(两个数之间)',
  value: 6
}]

export const numberValueOptions = [{
  label: '<',
  value: 1
}, {
  label: '≤',
  value: 2
}]

export const conditionModel = {
  conditionName: '条件',
  conditionDataList: [],
  examineDataList: [],
  isError: false
}
// 部门负责人  
export const levelOption = [
  {
    label: '直接部门负责人',
    value: 1
  },
  {
    label: '第二级部门负责人',
    value: 2
  },
  {
    label: '第三级部门负责人',
    value: 3
  },
  {
    label: '第四级部门负责人',
    value: 4
  },
  {
    label: '第五级部门负责人',
    value: 5
  },
]

// 部门负责人  
export const examineTypeOptions = [
  {
    label: '指定成员',
    value: 1
  },
  {
    label: '指定部门负责人',
    value: 2
  },
  {
    label: '指定角色',
    value: 3
  },

  {
    label: '连续多级部门负责人',
    value: 5
  },
  {
    label: '申请人本人',
    value: 11
  },
  {
    label: '发起人自选',
    value: 4
  },
]

// 多人审批方式
export const wayTypeOtions = [
  {
    label: '依次审批（按顺序依次审批）',
    value: 1
  },
  {
    label: '会签（须所有成员同意）',
    value: 2
  },
  {
    label: '或签（一名成员同意即可）',
    value: 3
  },
]

export const initConditionOption = {
  userList: [],
  conditionType: undefined,
  conditionNumber: undefined,
  conditionNumber_1: undefined,
  conditionNumberType_1: undefined,
  conditionNumberType_2: undefined,
  conditionNumber_2: undefined,
  values: undefined
}


export const examineStatusOptions = [
  { label: '审核中', value: 1 },
  { label: '已通过', value: 2 },
  { label: '已驳回', value: 3 },
  { label: '已撤销', value: 4 },
]


export const initApprovalFlow = {
  dataList: [
    {
      examineType: 11,
      name: '审批人',
      conditionList: [],
      deptList: [],
      examineErrorHandling: 1,
      parentLevel: 1,
      tempParentLevel: 1,
      roleId: "",
      roleTypeId: "",
      type: 3,
      userList: [],
      chooseType: 1,
      overType: 1,
      rangeType: 1,
      isError: false,
      id: 1
    },
    {
      examineType: 10,
      name: "抄送人",
      conditionList: [],
      deptList: [],
      examineErrorHandling: 1,
      parentLevel: 1,
      tempParentLevel: 1,
      roleId: "",
      roleTypeId: "",
      type: 3,
      userList: [],
      chooseType: 1,
      overType: 1,
      rangeType: 1,
      isError: false,
      id: 1,
      content: "直接部门负责人,申请人本人",
      selfCopy: 1,
      copyParentLevels: [1]
    }
  ],
}
