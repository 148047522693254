/**
 * @ Author: Your name
 * @ Create Time: 2023-11-22 11:09:58
 * @ Modified by: Your name
 * @ Modified time: 2024-09-27 15:58:45
 * @ Description: 业务模块store
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initContarctData, initOrderInfo } from './data';
type dictionaryModel = {
  [key: string]: {
    label: string;
    value: string;
    disabled?: boolean;
  }[]
}
interface initModel {
  orderDictionary: dictionaryModel | null;
  contractDictionary: dictionaryModel | null;
  orderInfo: orderInfoModel;
  salaryDataSource: any[];
  subsidyDataSource: any[];
  deductionDataSource: any[];
  feeStructureDataSource: feeStructureRowModel[];
  contractInfo: contractFormModel;
  fareInfo: businessFareInfoModel[];
}

const initialState: initModel = {
  orderDictionary: null,
  contractDictionary: null,
  orderInfo: JSON.parse(JSON.stringify(initOrderInfo)),
  subsidyDataSource: [],
  salaryDataSource: [],
  deductionDataSource: [],
  feeStructureDataSource: [],
  contractInfo: JSON.parse(JSON.stringify(initContarctData)),
  fareInfo: [],
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setContractDictionary: (state, action: PayloadAction<dictionaryModel>) => {
      state.contractDictionary = action.payload
    },
    setOrderDictionary: (state, action: PayloadAction<dictionaryModel | null>) => {
      state.orderDictionary = action.payload
    },
    setOrderInfo: (state, action: PayloadAction<orderInfoModel>) => { // 设置订单信息
      state.orderInfo = action.payload
    },
    setFareInfo: (state, action: PayloadAction<businessFareInfoModel[]>) => { // 车费信息
      state.fareInfo = action.payload
    },
    resetOrderInfo: (state) => { // 重置订单信息
      state.orderInfo = JSON.parse(JSON.stringify(initOrderInfo));
      state.orderDictionary = null;
    },
    setSalaryDataSource: (state, action: PayloadAction<any[]>) => { // 薪酬设置列表显示
      state.salaryDataSource = action.payload;
    },
    setSubsidyDataSource: (state, action: PayloadAction<any[]>) => { // 补贴列表显示
      state.subsidyDataSource = action.payload;
    },
    setDeductionDataSourcee: (state, action: PayloadAction<any[]>) => { // 扣减项列表显示
      state.deductionDataSource = action.payload;
    },
    setFeeStructureDataSource: (state, action: PayloadAction<feeStructureRowModel[]>) => { // 签单价格列表显示
      state.feeStructureDataSource = action.payload;
    },
    setContractInfo: (state, action: PayloadAction<contractFormModel>) => { // 设置合同信息
      state.contractInfo = action.payload
    },
    resetContractInfo: (state) => { // 重置合同信息
      state.contractInfo = JSON.parse(JSON.stringify(initContarctData));
      state.orderDictionary = null;
    },
  },
})

export const {
  setContractDictionary,
  setOrderDictionary,
  setOrderInfo,
  resetOrderInfo,
  setSubsidyDataSource,
  setSalaryDataSource,
  setDeductionDataSourcee,
  setFeeStructureDataSource,
  setContractInfo,
  resetContractInfo,
  setFareInfo
} = businessSlice.actions

export default businessSlice.reducer